<template>
  <div class="header" ref="header">
    <div class="jituan"></div>
    <div class="nav">
      <div
        class="nav-son"
        v-for="(item, index) in navlist"
        :key="index"
        @click.self="goto(item)"
        :class="item.type == type ? 'active' : ''"
        @mouseover="item.isshow = false"
        @mouseout="item.isshow = true"
      >
        {{ item.name }}
        <img
          :src="item.isshow ? item.xia : item.shang"
          alt=""
          class="icon"
          v-if="item.list.length > 0"
        />
        <img
          :src="item.isshow ? item.xia : item.shang"
          alt=""
          class="icon"
          v-if="item.teshu.length > 0"
        />
        <!-- 蓝色条 -->
        <!-- <em v-if="item.type == type"></em> -->
        <div class="box">
          <div class="more" v-for="(v, i) in item.son" :key="i" @click="go(v)">
            {{ v.name }}
          </div>
          <div
            class="more"
            v-for="(v, i) in item.list"
            :key="i"
            @click="goson(v)"
          >
            {{ v.name }}
          </div>
        </div>

        <!-- 方案中心二级菜单 -->
        <div class="fangan" v-if="item.teshu.length > 0">
          <div class="left">
            <div
              class="left-son"
              v-for="(x, z) in item.teshu"
              :key="z"
              @mouseover="teshuindex = z"
              @click="gomore(x)"
            >
              {{ x.name }}
            </div>
          </div>
          <div class="right">
            <div class="jiejue">
              解决方案 <i class="el-icon-arrow-right"></i>
            </div>
            <div class="right-list">
              <!-- {{ item.teshu[0].more }} -->
              <div
                class="right-list-son"
                v-for="(q, w) in item.teshu[teshuindex].more"
                :key="w"
                @click="son(q)"
              >
                {{ q.name }}
                <div class="em"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- <div class="phone"></div>
    <div class="number">400-8878-352</div> -->
  </div>
</template>

<script>
export default {
  props: ["neesscrollbool", "bg"],
  data() {
    return {
      type: 0,
      moreType: "4-0",
      teshuindex: 0,
      navlist: [
        {
          type: 0,
          name: "首页",
          path: "/index",
          from: "index",
          son: [],
          list: [],
          teshu: [],
        },
        {
          type: 1,
          name: "关于汉康",
          path: "/about",
          from: "about",
          son: [],
          teshu: [],
          list: [
            { name: "成都汉康", path: "/about" },
            // { name: "■■■■", path: "/about/jituanhk" },
            { name: "专家团队", path: "/about/zhuanjian" },
            { name: "汉康之歌", path: "/about/song" },
            { name: "企业文化", path: "/about/wenhua" },
          ],
          shang: require("../assets/imgs/home/icon@2x (15).png"),
          xia: require("../assets/imgs/home/icon@2x (16).png"),
          isshow: true,
        },
        {
          type: 2,
          name: "营销中心",
          path: "/marketingCenter",
          from: "marketingCenter",
          son: [],
          list: [],
          teshu: [],
        },
        {
          type: 3,
          name: "产品中心",
          from: "productCenter",
          path: "/productCenter",
          son: [],
          teshu: [],
          list: [
            { name: "智慧水务产品", path: "/shuiwu" },
            { name: "城市安全产品 ", path: "/chengshianquan" },
            { name: "智慧城市产品", path: "/zhihui" },
            { name: "智能设备产品 ", path: "/shebei" },
            { name: "智慧工具产品 ", path: "/gongju" },
          ],
          shang: require("../assets/imgs/home/icon@2x (15).png"),
          xia: require("../assets/imgs/home/icon@2x (16).png"),
          isshow: true,
        },
        {
          type: 4,
          name: "方案中心",
          path: "/solutionCenter",
          from: "solutionCenter",
          son: [],
          list: [],
          teshu: [
            {
              name: "智慧水务解决方案",
              path: "zhihuishuiwu",
              more: [
                { name: "智慧防汛方案", path: "zhihuifangxun" },
                { name: "智慧供水方案", path: "zhihuigongshui" },
                { name: "智慧排水方案", path: "zhihuipaishui" },
                { name: "智慧河湖方案", path: "zhihuihehu" },
              ],
              moreType: "4-0",
            },

            {
              name: "智慧城市解决方案",
              path: "zhihuichengshi",
              more: [
                { name: "城市大脑方案", path: "chengshidanao" },
                { name: "智慧交通方案", path: "zhihuijiaotong" },
                { name: "智慧文旅方案", path: "zhihuiwenlv" },
                { name: "智慧环保方案", path: "zhihuihuanbao" },
                { name: "智慧教育方案", path: "zhihuijiaoyu" },
                { name: "智慧园区方案", path: "zhihuiyuanqu" },
              ],
              moreType: "4-1",
            },
            {
              name: "城市安全解决方案",
              path: "/solutionCenter/anquan",
              more: [
                { name: "地质灾害监测预警" },
                { name: "道桥健康监测预警" },
                { name: "水、土气监测预警" },
                { name: "城市管网监测预警" },
                { name: "应急管理决策支持" },
              ],
              moreType: "4-2",
            },
            {
              name: "智慧政务解决方案",
              path: "/solutionCenter/zhengwu",
              more: [{ name: "电子证照系统" }, { name: "政务服务系统 " }],
              moreType: "4-3",
            },
          ],
          shang: require("../assets/imgs/home/i-s (1).png"),
          xia: require("../assets/imgs/home/i-s (2).png"),
          isshow: true,
        },
        {
          type: 5,
          name: "案例中心",
          path: "/chenggong",
          from: "chenggong",
          son: [],
          other: [],
          list: [],
          teshu: [],
        },
        {
          type: 6,
          name: "服务中心",
          path: "/serviceCenter",
          from: "serviceCenter",
          son: [],
          list: [],
          teshu: [],
        },
        {
          type: 7,
          name: "新闻中心",
          path: "/pressCenter",
          from: "pressCenter",
          son: [],
          other: [{ path: "two" }],
          list: [],
          teshu: [],
        },
      ],

      arr: [],
      window: null,
      scrollbool: true,
    };
  },
  created() {
    for (let item of this.navlist) {
      if (item.from == this.$route.meta.name) {
        this.type = item.type;
      }
    }

    this.scrollbool = this.neesscrollbool;
    //console.log("this.$route :>> ", this.$route);
  },
  methods: {
    goto(item) {
      this.$router.push(item.path);
    },
    go(v) {
      this.$router.push(v.path);
    },
    goson(v) {
      this.$router.push(v.path);
    },
    gomore(v) {
      this.$router.push({ name: v.path, params: v });
    },
    son(v) {
      this.$router.push({ name: v.path });
    },
    scroll() {
      // let scroll = document.body.scrollTop;
      let scroll = document.body.scrollTop;
      // console.log("object :>> ", scroll);
      this.arr.push(scroll);
      // 判断导航栏显示位置
      if (this.arr.length > 2) {
        let a = [this.arr[this.arr.length - 1]];
        let b = [this.arr[this.arr.length - 2]];

        if (a - b >= 0) {
          // console.log("往下滑");
          if (scroll > 250) {
            this.$refs.header.style.backgroundColor = "#fff";
          }
        } else {
          // console.log("往上滑");
          if (scroll < 250) {
            this.$refs.header.style.backgroundColor = "";
          }
        }
      }
    },
  },
  watch: {
    bg: {
      deep: true,
      handler: function (now) {
        this.$refs.header.style.backgroundColor = now;
      },
    },
  },
  mounted() {
    // let arr = [];
    // let that = this;
    this.$refs.header.style.backgroundColor = this.bg;

    window.addEventListener("scroll", this.scroll, true);
    // if (this.scrollbool) {
    //为了 区分新闻中心详情的导航栏
    //   window.addEventListener("scroll", this.scroll, true);
    // }
  },
  beforeDestroy() {
    // this.window = null;

    window.removeEventListener("scroll", this.scroll, true);
  },
};
</script>

<style lang="scss" scoped>
.active {
  font-size: 15px !important;
  font-family: Alibaba PuHuiTi !important;

  color: rgba(24, 24, 24, 1) !important;
}
.header:hover {
  background-color: #fff;
}
.header {
  position: absolute;
  //// width: 1902px;
  width: 1920px;
  height: 64px;
  //background-color: rgba(29, 29, 29, 1);
  //background-color: rgba(0, 0, 0, 1);
  backdrop-filter: blur(6px);

  display: flex;
  align-items: center;
  z-index: 100;

  .jituan {
    // width: 133px;
    // height: 38px;
    width: 330px;
    height: 70px;
    // background-image: url("../assets/imgs/home/black.png");
    background-image: url("../assets/imgs/home/jituan3.png");
    // background-color: red;
    background-size: 100% 100%;
    position: absolute;
    top: -2px;
    left: 40px;
  }
  .home {
    cursor: pointer;
  }
  .nav {
    display: flex;
    position: absolute;
    right: 40px;
    .nav-son {
      // width: 96px;
      height: 64px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 15px;
      font-family: Alibaba PuHuiTi Xi;
      font-weight: 400;

      color: rgba(24, 24, 24, 0.6);
      padding: 0 18px;
      cursor: pointer;
      position: relative;
      .icon {
        width: 8px;
        height: 5px;
        // position: absolute;
        // top: 30px;
        // right: 6px;
        position: relative;
        left: 6px;
        top: 1px;
      }
      em {
        position: absolute;
        width: 114px;
        height: 3px;
        left: 0;
        bottom: 0px;
        background-color: rgba(68, 112, 255, 1);
      }
      .box {
        position: absolute;
        left: -40px;
        top: 64px;
        width: 170px;
        display: flex;
        flex-direction: column;
        // background-color: rgba(0, 0, 0, 1);
        background-color: #fff;
        // backdrop-filter: blur(10px);
        display: none;

        .more {
          width: 170px;
          height: 50px;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 14px;
          font-family: Alibaba PuHuiTi Xi;
          font-weight: 400;
          line-height: 20px;
          color: rgba(24, 24, 24, 1);

          cursor: pointer;
          opacity: 0.4;
        }
        .more:hover {
          font-family: Alibaba PuHuiTi;
          background: rgba(55, 55, 55, 0.02);
          opacity: 1;
          color: rgba(24, 24, 24, 1);
        }
      }
      .fangan {
        position: absolute;
        right: -280px;
        top: 64px;
        width: 1116px;
        height: 252px;
        display: flex;

        display: none;
        // opacity: 0;
        .left {
          width: 196px;
          height: 100%;
          background-color: rgba(255, 255, 255, 1);
          display: flex;
          flex-direction: column;
          // justify-content: space-around;
          padding-top: 20px;
          // margin-left: 1px;

          .left-son {
            width: 100%;
            height: 50px;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 14px;
            font-family: Alibaba PuHuiTi Xi;
            font-weight: 400;
            line-height: 20px;

            color: #181818;
            opacity: 0.6;
          }
          .left-son:hover {
            font-family: Alibaba PuHuiTi;
            background: rgba(245, 245, 245, 1);
            opacity: 1;
          }
        }
        .right {
          width: 920px;
          height: 100%;

          background: rgba(249, 249, 249, 1);

          padding: 35px 70px 0 70px;
          .jiejue {
            font-size: 14px;
            font-family: Alibaba PuHuiTi;
            font-weight: 400;
            line-height: 20px;

            color: #181818;
            display: flex;
            align-items: center;
            margin-bottom: 16px;
            i {
              margin-left: 10px;
            }
          }

          .right-list {
            border-top: 1px solid #e7e7e7;
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            // justify-content: space-between;
            padding-top: 30px;

            .right-list-son {
              font-size: 14px;
              font-family: Alibaba PuHuiTi Xi;
              font-weight: 400;
              line-height: 24px;

              color: #181818;
              position: relative;
              opacity: 0.6;
              margin-right: 80px;
              margin-bottom: 36px;
              .em {
                position: absolute;
                bottom: -8px;
                width: 0%;
                height: 2px;
                background-color: #4470ff;
                opacity: 0;
                transition: all 0.3s linear;
              }
            }
            .right-list-son:hover {
              font-family: Alibaba PuHuiTi;
              font-weight: 700;
              opacity: 1;
            }
            .right-list-son:hover .em {
              opacity: 1;
              width: 100%;
            }
          }
        }
      }
    }
    .nav-son:hover {
      // color: rgba(68, 112, 255, 1);
      color: rgba(24, 24, 24, 1);
      font-family: Alibaba PuHuiTi;
    }
    .nav-son:hover .box {
      display: block;
    }
    .nav-son:hover .fangan {
      // display: block;
      display: flex;
    }
  }
  .wx {
    width: 22px;
    height: 22px;
    background-image: url("../assets/imgs/home/wx (1).png");
    background-size: 100% 100%;
    position: absolute;
    right: 190px;
    cursor: pointer;
    .img {
      width: 76px;
      height: 76px;
      background-image: url("../assets/imgs/home/gongzonghao.png");
      background-size: 100% 100%;
      position: absolute;
      bottom: -90px;
      left: -20px;
      display: none;
      img {
        position: absolute;
        top: -10px;
        left: 32px;
      }
    }
  }

  .wx:hover .img {
    display: block;
  }
  .phone {
    width: 22px;
    height: 22px;
    background-image: url("../assets/imgs/home/blackph.png");
    background-size: 100% 100%;
    position: absolute;
    right: 156px;
  }
  .number {
    height: 100%;
    display: flex;
    align-items: center;
    position: absolute;
    font-size: 18px;
    font-family: Alibaba PuHuiTi;
    font-weight: bold;
    line-height: 31px;
    color: #181818;

    right: 24px;
  }
}
</style>
